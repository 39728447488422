import { Component, OnInit } from '@angular/core'
import { FormArray, FormBuilder, Validators } from '@angular/forms'
import { MatDatepickerInputEvent } from '@angular/material/datepicker'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AjusteProducto } from '@app/_models/ajusteProducto/ajusteProducto'
import { Producto } from '@app/_models/productos/producto'
import { BodegaService } from '@app/_pages/inventario/bodega/bodega.service'
import { ProductosService } from '@app/_pages/productos/productos.service'
import { NgSelectConfig } from '@ng-select/ng-select'
//import { PrimeNGConfig } from 'primeng/api';
import { first } from 'rxjs/operators'
import { AjusteInventario } from '../../../_models/ajusteInventario/ajusteInventario'
import { AjusteInventarioService } from '../ajusteInventario.service'
import { AjusteProductoService } from '../../ajusteProducto/ajusteProducto.service'
import { MatSelectChange } from '@angular/material/select'
import { StockSaliente } from '@app/_models/stocks/stockSaliente'

@Component({
  selector: 'app-ajuste-inventario-form-salida',
  templateUrl: './ajuste-inventario-form-salida.component.html',
  styleUrls: ['./ajuste-inventario-form-salida.component.scss']
})
export class AjusteInventarioFormSalidaComponent implements OnInit {
  ajusteInventario: AjusteInventario = new AjusteInventario()
  ajusteProducto: AjusteProducto = new AjusteProducto()
  stockSaliente: StockSaliente = new StockSaliente()

  productos2: any[] = []
  productoForm: any
  producto: any
  id: any
  render: number = 0
  subtotal: number[] = []
  valorUnitario: number = 0
  productosSeleccionados: any
  lista_productos: any
  events: string[] = []
  idFormulario: number = 0
  bodegas: any
  nose: any
  Costo: number[] = []
  ajuste: number[] = []
  dataAjusteInventario: any
  idAjuste: any
  total: number = 0
  resta: any
  addButton: boolean

  addressForm = this.fb.group({
    fecha: [''],
    documento: ['', Validators.required],
    bodega: ['', Validators.required],
    observacion: ['', Validators.required],
    stock: []
  })

  addressForm2 = this.fb.group({
    productos: this.fb.array([])
  })
  selectedBodegaId: any
  onSelectionChange2: any
  stockActual: any
  result: any

  get productos() {
    return this.addressForm2.get('productos') as FormArray
  }

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private productosService: ProductosService,
    private ajusteInventarioService: AjusteInventarioService,
    private config: NgSelectConfig,
    private bodegasService: BodegaService,
    private ajusteProductoService: AjusteProductoService
  ) {}

  agregarProducto() {
    this.ajusteInventarioService
      .ajusteInventarioGet()
      .pipe(first())
      .subscribe((result: AjusteInventario[]) => {
        this.dataAjusteInventario = result.map((AjusteInventario) => {
          return AjusteInventario
        })
        console.log('data', this.dataAjusteInventario)
        this.idAjuste =
          this.dataAjusteInventario[this.dataAjusteInventario.length - 1].id
        this.total = this.idAjuste + 1
        console.log('nosee', this.total)

        const productoForm = this.fb.group({
          productosSeleccionados: [''],
          ubicacion: [''],
          cantidad: [''],
          stock: [''],
          ajuste: [''],
          idAjusteInventario: [this.total]
        })
        this.productos.push(productoForm)
      })
  }

  eliminarProducto(indice: number) {
    this.productos.removeAt(indice)
  }

  ngOnInit(): void {
    // this.getProductosss()
    this.getBodegas()
    this.agregarProducto()
  }

  onSelectionChange(event: MatSelectChange): void {
    this.selectedBodegaId = event.value

    if(this.selectedBodegaId){
      this.addButton = true;
    this.ajusteInventarioService
   .getProductByBodega(this.selectedBodegaId)
   .subscribe((res) => {
     this.lista_productos = res
   })
    }else {
      this.addButton = false;
    }

   
  }

  getBodegas() {
    this.bodegasService
      .bodegasGetAll()
      .pipe(first())
      .subscribe((bodegas) => {
        this.bodegas = bodegas
      })
  }
  // getProductosss() {
  //   // this.productosService.productoGet().subscribe((res) => {
  //   //   this.lista_productos = res
  //   //   console.log(this.lista_productos)
  //   // })
  // }
  magia(event: { value: number }, i: number) {
    const idBodega = this.addressForm.get('bodega')?.value 
    const idProducto = event.value 

    if (idBodega && idProducto) {
     
      this.productosService
        .getProductByid(idProducto, idBodega)
        .pipe(first())
        .subscribe({
          next: (x: any) => {
            const nuevoStock = x[0]?.stockActual 
            const productoGroup = this.productos.at(i)
            const stockControl = productoGroup.get('stock')

            if (stockControl) {
              stockControl.setValue(nuevoStock)
            }
          },
          error: (err) => {
            console.error('Error al obtener producto por ID:', err)
          }
        })

      this.productosService.productoGetId(idProducto).subscribe({
        next: (res: { neto: number }) => {
          if (!this.Costo[i]) {
            this.Costo[i] = res.neto
          } else {
            this.Costo[i] += res.neto
          }
          this.subtotal[i] =
            this.productos.value[i].cantidad * this.Costo[i] || 0
          this.ajuste[i] =
            (this.ajuste[i] || 0) + this.productos.value[i].cantidad
        },
        error: (err) => {
          console.error('Error al obtener producto:', err)
        }
      })
    }
  }

  calcularSubTotal() {}

  obtenerProductos(event: any) {
    const idProducto = event.value
    const idBodega = this.addressForm.get('bodega').value
    console.log('Bodega seleccionada al enviar:', idBodega)
  }

  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement
    let value = parseInt(input.value, 10)
    let result = 0

    if (isNaN(value)) {
      this.resta = 0
      return
    }

    if (this.stockActual < value) {
      this.snackBar.open(
        'La cantidad no puede ser mayor al stock actual',
        'cerrar',
        {
          duration: 2000,
          verticalPosition: 'top'
        }
      )
      return
    }

    this.resta = this.stockActual - value
    console.log('resta resultado', this.resta)
  }

  onSubmit() {
    switch (this.addressForm.status) {
      case 'VALID':
        //Obtener datos de productos
        this.ajusteInventario.tipoAjuste = 'salida'
        this.ajusteInventario.fechaEmision = this.addressForm.value.fecha
        this.ajusteInventario.numeroDocumento = this.addressForm.value.documento
        this.ajusteInventario.idBodega = this.addressForm.value.bodega
        this.ajusteInventario.observacion = this.addressForm.value.observacion
        this.ajusteInventario.estado = 'valido'

        this.ajusteInventarioService
          .create(this.ajusteInventario)
          .pipe()
          .subscribe(
            (data) => {
              this.snackBar.open(
                'Ajuste Inventario ingresado con exito',
                'cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
              this.addressForm.reset()
            },
            (error) => {
              this.snackBar.open(
                'No se pudo ingresar el ajuste de inventario, contacte con informatica',
                'cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
            }
          )

        for (let i = 0; i < this.productos.value.length; i++) {
          this.ajusteProducto.idProducto =
            this.productos.value[i].productosSeleccionados
          this.ajusteProducto.cantidad = this.productos.value[i].cantidad
          this.ajusteProducto.ajuste = this.productos.value[i].ajuste
          this.ajusteProducto.ubicacion = this.productos.value[i].ubicacion
          this.ajusteProducto.idAjusteInventario =
            this.productos.value[i].idAjusteInventario

          //* modelo nuevo
          this.stockSaliente.fecha = this.addressForm.value.fecha
          this.stockSaliente.ndocumento_saliente =
            this.addressForm.value.documento
          this.stockSaliente.id_bodega = parseInt(
            this.addressForm.value.bodega,
            10
          )
          this.stockSaliente.observacion_saliente =
            this.addressForm.value.observacion
          this.stockSaliente.ubicacion_saliente =
            this.productos.value[i].ubicacion
          this.stockSaliente.tipoAjuste = 'salida'
          this.stockSaliente.idAjusteInventario =
            this.productos.value[i].idAjusteInventario
          this.stockSaliente.idProducto =
            this.productos.value[i].productosSeleccionados
          this.stockSaliente.cantidad_saliente =
            this.productos.value[i].cantidad

          this.ajusteInventarioService
            .restarStockSaliente(this.stockSaliente)
            .pipe()
            .subscribe(
              (data) => {
                this.snackBar.open('Correcto', 'cerrar', {
                  duration: 2000,
                  verticalPosition: 'top'
                })
                this.addressForm2.reset()
              },
              (error) => {
                this.snackBar.open(
                  'Error, contacte con informatica',
                  'cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
              }
            )

          this.ajusteProductoService
            .createAjusteProducto(this.ajusteProducto)
            .pipe()
            .subscribe(
              (data) => {
                this.snackBar.open(
                  'Ajuste Producto ingresado con exito',
                  'cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
                this.addressForm2.reset()
              },
              (error) => {
                this.snackBar.open(
                  'No se pudo ingresar la variante, contacte con informatica',
                  'cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
              }
            )
        }
    }
  }
}
